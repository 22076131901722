<template>
    <div>
        <v-container class="main-text text-container text-center" style="min-height: 60vh;">
            <v-row>
                <v-col class="mt-10 heading">
                    <span v-if="language === 'en'">Reset Password</span>
                    <span v-else>Ανάκτηση Κωδικού</span>
                </v-col>
            </v-row>
            <div v-show="!resetSuccess">
                <v-row>
                    <v-col>
                        <div style="width: 40%;margin:auto;">
                            <v-text-field
                                v-model="email"
                                label="Email"
                                required
                            ></v-text-field>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-btn
                            elevation="0"
                            color="#6BB00B"
                            class="login"
                            @click="submit"
                        >
                            <span v-if="language === 'en'">Reset Password</span>
                            <span v-else>Ανακτηση κωδικου</span>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-show="invalid">
                    <v-col style="color: red;">
                        <span v-if="language === 'en'">Invalid Email</span>
                        <span v-else>Άκυρη διεύθυνση ηλεκτρονικού ταχυδρομείου</span>
                    </v-col>
                </v-row>
            </div>
            <div v-show="resetSuccess" class="mt-5">
                <span v-if="language === 'en'">A password reset email has been sent to the email address on file for your account, but may take several minutes to show up in your inbox. Please wait at least 10 minutes before attempting another reset.</span>
                <span v-else>Ένα email επαναφοράς κωδικού πρόσβασης έχει σταλεί στη διεύθυνση email που έχει καταχωρηθεί για τον λογαριασμό σας, αλλά ενδέχεται να χρειαστούν αρκετά λεπτά για να εμφανιστεί στα εισερχόμενά σας. Περιμένετε τουλάχιστον 10 λεπτά πριν επιχειρήσετε άλλη επαναφορά.</span>
            </div>
        </v-container>
        <div>
            <div>
                <form action="https://nutrisense.online/crm/my-account/lost-password/" method="POST" ref="resetForm">
                    <input type="hidden" name="user_login" v-model="email">
                    <input type="hidden" name="wc_reset_password" value="true">
                    <input type="hidden" name="_wp_http_referer" value="/crm/my-account/lost-password/">
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
            email: '',
            resetSuccess: false,
            invalid: false
        }),
        computed: {
            language() {
                return this.$store.getters.getLanguage
            }
        },
        created() {
            if (window.location.hash === '#success') {
                this.resetSuccess = true
            }
            if (window.location.hash === '#invalid') {
                this.invalid = true
            }
        },
        methods: {
            submit() {
                if (this.email.length >= 4) {
                    this.$refs.resetForm.submit()
                }
            }
        }
    }
</script>
<style scoped>

.head-container{
    background-image: url("~@/assets/about_a_head.png");
    height: 380px;
    width: 100%;
    z-index: 0;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-shadow: 0px 0px 20px #0304036b;
    margin-top: -50px;
}

.head-text-main {
    font-weight: 700;
    font-size: 96px;
}

.head-text-secondary {
    font-size: 32px;
}

.main-text {
    color: #646464;
    font-size: 16px;
    line-height: 26px;
}
.text-container {
    max-width: 65vw;
}

.green-text {
    color: #006600;
    font-weight: bold;
}
iframe {
    border: 0px;
    width: 100%;
    height: 100vh
}
button {
    color: #fff!important;
}
.heading span {
    font-size: 2.4em;
    color: #000;
}
</style>
